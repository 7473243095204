.swiper-carousel {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  .swiper-slide,
  swiper-slide {
    position: relative;
  }
}
