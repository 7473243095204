// import Swiper styles
html,
body {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  background: black;
  color: #fff;
  line-height: 1.5;
  font-family: -apple-system, system-ui, 'Helvetica Neue', Helvetica, Arial,
    'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Custom styles for demo
:root {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
}
.swiper-carousel {
  padding-bottom: 32px;
  max-width: 1500px;
  .swiper-slide {
    width: 700px;
    height: auto;
    border-radius: 16px;
    // Slide background to match main background
    background: #18212b;
    max-width: calc(100% - 48px);
    @media screen and (max-width: 768px) {
      height: 320px;
      width: auto;
    }
  }
  .swiper-carousel-animate-opacity {
    height: 100%;
  }
  .swiper-pagination-bullets {
    bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 16px;
    // prettier-ignore
    -webkit-box-reflect: below 5px -webkit-linear-gradient(bottom,rgba(255,0,0,0.1) 0%,transparent 32px,transparent 100%);
  }
  .slide-content {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 88px 16px 24px;
    box-sizing: border-box;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75)
    );
    border-radius: 8px 8px 16px 16px;
    .title {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.1;
    }
    p {
      margin: 8px 0 0;
      opacity: 0.65;
      font-size: 14px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  // Hide navigation on narrow screens
  @media (max-width: 640px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none !important;
    }
  }
}
