:root {
    --auto-complete-input-color : #fff;
}

[data-reach-dialog-content] {
    background : transparent !important;
    padding: 16px;
}

.swiper-pagination-bullet-active {
    
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--auto-complete-input-color);
  box-shadow: 0 0 0px 1000px #00000000 inset;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
/* end */




::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #626262;
}

::-webkit-scrollbar-thumb:hover {
  background: #11c2c7;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: #626262;
}

::-webkit-scrollbar-thumb {
  border-radius: 2em;
  background: #00ffff;
}
